import React, {Component} from 'react'
import Intro from './Intro'
import CategoryList from './CategoryList'
import Category from './Category'

const Main = () => (
    <React.Fragment>
        <Intro />
        <CategoryList />
    </React.Fragment>
)

export default Main