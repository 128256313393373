import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: './assets/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      order: ['navigator', 'cookie', 'sessionStorage', 'htmlTag', 'querystring',  'localStorage', 'path', 'subdomain']
    },
    lng: "pl",
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;