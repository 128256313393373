import React, { Component } from 'react'
import "./Intro.scss"
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.compat.css"
import IconCode from './IconCode'
import IconTest from './IconTest'
import IconReact from './IconReact'
import IconMobile from './IconMobile'
import IconCertificate from './IconCertificate'
import IconTime from './IconTime'
import IconMeasure from './IconMeasure'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";


const Intro = () => {
    const { t, i18n } = useTranslation(['about']);
    return (
    <div className="intro">
        <Container fluid="md">
            <Row>
                <Col>
                    <div className="intro__texts">
                        <h1 className="intro__heading">{t('presentation')}</h1>
                        <p className="intro__text">{t('presentation1')}</p>
                        <p className="intro__text">{t('presentation2')}</p>
                    </div>
                    
                </Col>
            </Row>
            <Row xs={2} sm={4}>
                <Col>
                    <IconCode className="intro__icon" />
                    <a className="intro__link intro__text--bolded" href="">{t('skill1')}</a>
                </Col>
                <Col>
                    <IconMobile className="intro__icon" />
                    <a className="intro__link intro__text--bolded" href="">{t('skill2')}</a>
                </Col>
                <Col>
                    <IconReact className="intro__icon" />
                    <a className="intro__link intro__text--bolded" href="">{t('skill3')}</a>
                </Col>
                <Col>
                    <IconTest className="intro__icon" />
                    <a className="intro__link intro__text--bolded" href="">{t('skill4')}</a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="intro__texts intro__texts--nomargin">
                        <p className="intro__text">{t('presentation3')}</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="intro__links">
                        <Link to="/portfolio" className="intro__link intro--rowed"><strong className="">{t('check_portfolio')}</strong></Link>
                        <Link to="/contact" className="intro__link intro--rowed"><strong>{t('contact_me')}</strong></Link>
                    </div>
                </Col>
            </Row>
        </Container>
        <div className="intro__characteristics">
            <Container>
                <Row>
                    <Col>
                        <h2 className="intro__secondary"><strong>{t('quality')}</strong></h2>
                    </Col>
                </Row>
            </Container>
            <ScrollAnimation animateIn="fadeInUp" duration={1.5} animateOnce={true}>
                <Container>
                    <Row xs={3} sm={3}>
                        <Col>
                            <IconCertificate  className="intro__icon--big" />
                            <p className="intro__advantage intro__text intro__text--spaced intro__text--bolded">{t('quality1')}</p>
                        </Col>
                        <Col>
                            <IconTime className="intro__icon--big" />
                            <p className="intro__advantage intro__text intro__text--spaced intro__text--bolded">{t('quality2')}</p>
                        </Col>
                        <Col>
                            <IconMeasure className="intro__icon--big"/>
                            <p className="intro__advantage intro__text intro__text--spaced intro__text--bolded">{t('quality3')}</p>
                        </Col>
                    </Row>
                </Container>
            </ScrollAnimation>

        </div>
    </div>
)
}

export default Intro