import React, { Component } from 'react'
import { Map, GoogleApiWrapper } from 'google-maps-react'

import './MapContainer.scss'

const mapStyles = {
    height: '100%',
    width: '100%'
}

class MapContainer extends Component{
    render() {
        return (
            <div className="mapcontainer">
                <Map 
                    google={this.props.google}
                    zoom={8}
                    style={mapStyles}
                    initialCenter={
                        {
                            lat: 51.113486,
                            lng: 17.006109
                        }
                    }
                    />
            </div>
        )
    }
    
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAFxNGvuGQhtgBK3vtBCX1yRPnJhziCVP0'
})(MapContainer)

