import * as React from "react";

function IconMobile(props) {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
      <path
        d="M405.162 33.511v444.98c0 18.5-15.009 33.509-33.521 33.509H42.345c-18.512 0-33.509-15.009-33.509-33.509V33.511C8.836 15 23.834.002 42.345.002h329.296c18.512 0 33.521 14.999 33.521 33.509z"
        fill="#424c59"
      />
      <path
        d="M405.162 33.509v52.618H8.838V33.509C8.838 14.999 23.835 0 42.347 0h329.294c18.512 0 33.521 14.999 33.521 33.509z"
        fill="#eaeaea"
      />
      <circle cx={62.22} cy={42.22} r={17.259} fill="#f9572b" />
      <circle cx={120.332} cy={42.22} r={17.259} fill="#fcc63d" />
      <g fill="#45b8c4">
        <circle cx={178.434} cy={42.22} r={17.259} />
        <circle cx={403.836} cy={158.549} r={99.328} />
      </g>
      <path
        d="M265.857 155.525H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h174.078a7.842 7.842 0 01.001 15.684z"
        fill="#fcc63d"
      />
      <path
        d="M56.759 155.52c-7.185 0-10.584-9.333-4.979-13.897 5.543-4.514 14.119.481 12.665 7.592-.74 3.62-3.985 6.305-7.686 6.305z"
        fill="#fff"
      />
      <path
        d="M265.857 243.147H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h174.078a7.842 7.842 0 01.001 15.684z"
        fill="#fcc63d"
      />
      <g fill="#fff">
        <path d="M56.759 243.148c-3.281 0-6.273-2.12-7.385-5.204-1.094-3.035-.162-6.542 2.307-8.624 5.254-4.433 13.57-.118 12.881 6.753-.396 3.954-3.829 7.075-7.803 7.075zM336.428 286.958h-49.135a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h49.135a7.841 7.841 0 017.842 7.842 7.843 7.843 0 01-7.842 7.842z" />
      </g>
      <path
        d="M261.151 286.958h-91.479a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h91.479a7.842 7.842 0 010 15.684z"
        fill="#f9572b"
      />
      <g fill="#fff">
        <path d="M143.531 286.958H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h51.753a7.841 7.841 0 017.842 7.842 7.841 7.841 0 01-7.842 7.842zM56.759 286.958c-7.071 0-10.494-9.039-5.193-13.717 5.281-4.66 13.871-.173 12.973 6.846-.496 3.866-3.87 6.871-7.78 6.871z" />
      </g>
      <path
        d="M218.808 330.769H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h127.029a7.841 7.841 0 017.842 7.842 7.841 7.841 0 01-7.841 7.842z"
        fill="#fcc63d"
      />
      <path
        d="M56.759 330.767c-3.139 0-6.043-1.945-7.246-4.841-1.359-3.273-.291-7.169 2.557-9.285 5.704-4.236 13.966 1.063 12.321 8.055-.823 3.507-4.02 6.071-7.632 6.071z"
        fill="#fff"
      />
      <path
        d="M297.221 374.58h-24.044a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h24.044a7.842 7.842 0 010 15.684z"
        fill="#f9572b"
      />
      <path
        d="M247.037 374.58h-69.524a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h69.524a7.842 7.842 0 010 15.684z"
        fill="#fcc63d"
      />
      <g fill="#fff">
        <path d="M151.372 374.58H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h59.594a7.843 7.843 0 010 15.684zM56.759 374.576c-7.06 0-10.493-9.01-5.213-13.7 5.281-4.691 13.889-.186 12.992 6.833-.494 3.869-3.87 6.867-7.779 6.867zM333.292 418.39H221.424c-4.332 0-7.842-3.511-7.842-7.842s3.51-7.842 7.842-7.842h111.868a7.842 7.842 0 010 15.684z" />
      </g>
      <path
        d="M195.283 418.39H91.778c-4.332 0-7.842-3.511-7.842-7.842s3.51-7.842 7.842-7.842h103.505c4.332 0 7.842 3.511 7.842 7.842s-3.51 7.842-7.842 7.842z"
        fill="#f9572b"
      />
      <path
        d="M56.759 418.395c-7.005 0-10.47-8.936-5.272-13.656 5.174-4.698 13.786-.431 13.074 6.577-.401 3.948-3.826 7.079-7.802 7.079z"
        fill="#fff"
      />
      <path
        d="M143.531 462.201H91.778c-4.332 0-7.842-3.511-7.842-7.842s3.51-7.842 7.842-7.842h51.753c4.332 0 7.842 3.511 7.842 7.842s-3.51 7.842-7.842 7.842z"
        fill="#f9572b"
      />
      <path
        d="M56.759 462.204c-3.277 0-6.282-2.121-7.385-5.204-1.165-3.253-.002-7.024 2.827-9.024 5.628-3.978 13.649 1.047 12.244 7.912-.742 3.619-3.984 6.316-7.686 6.316z"
        fill="#fff"
      />
      <path
        d="M130.984 199.336H91.778a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h39.206a7.841 7.841 0 017.842 7.842 7.841 7.841 0 01-7.842 7.842z"
        fill="#f9572b"
      />
      <g fill="#fff">
        <path d="M234.491 199.336h-75.276a7.841 7.841 0 01-7.842-7.842 7.841 7.841 0 017.842-7.842h75.276a7.841 7.841 0 017.842 7.842 7.843 7.843 0 01-7.842 7.842zM56.759 199.34c-3.218 0-6.156-2.04-7.315-5.03-1.234-3.183-.205-6.899 2.487-8.996 5.573-4.342 13.949.707 12.513 7.711-.741 3.617-3.983 6.315-7.685 6.315zM399.132 211.085a7.836 7.836 0 01-5.656-2.411l-37.638-39.206a7.841 7.841 0 1111.313-10.86l30.107 31.362 41.983-79.768a7.842 7.842 0 1113.879 7.305l-47.048 89.391a7.838 7.838 0 01-6.94 4.187z" />
      </g>
    </svg>
  );
}

const MemoIconMobile = React.memo(IconMobile);
export default MemoIconMobile;
