import React from 'react';
import './Skills.scss'
import { Container, Row, Col } from 'react-bootstrap'
import IconVisualStudioCode from './IconVisualStudioCode'
import IconSass from './IconSass'
import IconRedux from './IconRedux'
import IconReact from './IconReact'
import IconNode from './IconNode'
import IconJquery from './IconJquery'
import IconJavascript from './IconJavascript'
import IconJava from './IconJava'
import IconHtml from './IconHtml'
import IconCss from './IconCss'
import IconBootstrap from './IconBootstrap'
import IconWordpress from './IconWordpress'
import IconSelenium from './IconSelenium'
import IconIllustrator from './IconIllustrator'
import IconPhotoshop from './IconPhotoshop'
import IconGit from './IconGit'
import Icon from './IconSass'
import { useTranslation } from "react-i18next";

export default function Skills() {
    const { t, i18n } = useTranslation(['skills']);
    return (
        <div className="Skills">
            <Container>
            <h2 className="Skills__heading"><strong>{t('skills')}</strong></h2>
                <Row className="flex-sm-row-reverse">
                    <Col xs={12} sm={4}>
                        <div className="Skills__icons">
                            <h4 className="Skills__header">{t('stack')}</h4>
                            <ul className="Skills__list">
                                <li className="Skills__item"><IconReact className="Skills__icon"/> React</li>
                                <li className="Skills__item"><IconRedux className="Skills__icon" /> Redux</li>
                                <li className="Skills__item"><IconJavascript className="Skills__icon" /> Javascript</li>
                                <li className="Skills__item"><IconNode className="Skills__icon" /> NodeJS</li>
                                <li className="Skills__item"><IconVisualStudioCode className="Skills__icon" /> Visual Code</li>
                                <li className="Skills__item"><IconGit className="Skills__icon" /> Git</li>
                                <li className="Skills__item"><IconHtml className="Skills__icon" /> HTML</li>
                                <li className="Skills__item"><IconCss className="Skills__icon" /> CSS</li>
                                <li className="Skills__item"><IconSass className="Skills__icon" /> Sass</li>
                                <li className="Skills__item"><IconBootstrap className="Skills__icon" /> Bootstrap</li>
                                <li className="Skills__item"><IconWordpress className="Skills__icon" /> Wordpress</li>
                                <li className="Skills__item"><IconJava className="Skills__icon" /> Java</li>
                                <li className="Skills__item"><IconSelenium className="Skills__icon" /> Selenium</li>
                                <li className="Skills__item"><IconPhotoshop className="Skills__icon" /> Photoshop</li>
                                <li className="Skills__item"><IconIllustrator className="Skills__icon" /> Illustrator</li>
                                <li><p className="Skills__text--italic">{t('more')}</p></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={8}>
                        <div dangerouslySetInnerHTML={{__html: t('text')}} />
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}

