import { CHANGE_LANG, CHANGE_OPENED } from '../constants/action-types'

export function changeLang(payload) {
    return {
        type: CHANGE_LANG,
        payload
    }
}

export function changeOpened() {
    return {
        type: CHANGE_OPENED
    }
}
