import React from 'react';
import bg1 from '../img/bg1.jpg'
import bg2 from '../img/bg2.jpg'
import './Portfolio.scss'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useTranslation } from "react-i18next";

const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
}

const Portfolio = () => {
    const { t, i18n } = useTranslation(['portfolio']);
    return (
        <div className="Portfolio">
            <Container>
                <h2 className="Portfolio__heading"><strong>{t('portfolio')}</strong></h2>
                <Slider {...settings}>
                    <div>
                        <div className="Portfolio__slide" style={{backgroundImage: `url(${bg1})`}}>
                            <div className="Portfolio__image">
                                <div className="Portfolio__details">
                                    <h1 key="h1" className="Portfolio__heading">Wszystko zaczyna się od... brwi</h1>
                                    <p key="p" className="Portfolio__text">{t('project1')}</p>
                                    <a href="http://wszystkozaczynasieodbrwi.pl" className="Portfolio__link">wszystkozaczynasieodbrwi.pl</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="Portfolio__slide" style={{backgroundImage: `url(${bg1})`}}>
                            <div className="Portfolio__image">
                                <div className="Portfolio__details">
                                    <h1 key="h1" className="Portfolio__heading">Wszystko zaczyna się od... brwi</h1>
                                    <p key="p" className="Portfolio__text">{t('project1')}</p>
                                    <a href="http://wszystkozaczynasieodbrwi.pl" target="_blank" className="Portfolio__link">wszystkozaczynasieodbrwi.pl</a>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </Slider>
            </Container>
        </div>
    )
}

export default Portfolio