import * as React from "react";

function IconCss(props) {
  return (
    <svg viewBox="0 0 1771 2499.8" width="1em" height="1em" {...props}>
      <path
        d="M1387.8 92.5h-146.9l152.8 165.9v78.9h-314.8v-92.4h152.8L1078.9 79V0h309zm-371.4 0H869.3l153 165.9v78.9H707.4v-92.4h152.8L707.4 79V0h309zm-367.8 3.8H490.1v144.8h158.5v96.3H377.3V0h271.3z"
        fill="#131313"
      />
      <path
        d="M161.2 2299.1L0 491.4h1771l-161.4 1807.4-725.2 201z"
        fill="#1572b6"
      />
      <path d="M885.5 2346.2l586-162.5 137.8-1544.5H885.5z" fill="#33a9dc" />
      <path
        d="M885.5 1294.1h293.4l20.2-227H885.5V845.5h556l-5.3 59.5-54.5 611H885.5z"
        fill="#fff"
      />
      <path
        d="M886.7 1869.9h-1l-246.9-66.7-15.8-176.8H400.6l31.1 348.1 454.2 126.4h1.3v-231z"
        fill="#ebebeb"
      />
      <path
        d="M1160.1 1506.3l-26.7 296.7-247.3 66.7v231l454.5-126 3.3-37.5 38.6-431h-222.4z"
        fill="#fff"
      />
      <path
        d="M886.3 845.5v221.7H350.9l-4.6-49.8L336.2 905l-5.3-59.5zm-.8 448.6v221.7H641.4l-4.3-49.8-10.1-112.4-5.3-59.5h263.7z"
        fill="#ebebeb"
      />
    </svg>
  );
}

const MemoIconCss = React.memo(IconCss);
export default MemoIconCss;
