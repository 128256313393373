import React from 'react'
import './Category.scss'
import image from '../img/img1.jpg'


const Category = (props) => {
    let name = "category "
    name += props.half ? "category--half " : ""
    name += props.right ? "category--right " : "2"
    return (
        <div className={name}>
            <div className="category__container">
                <img className="category__image" src={props.image}/>
                {props.children}
            </div>  
        </div>
    )
}

export default Category