import React from 'react'
import Category from './Category'
import img1 from '../img/img1.jpg'
import img9 from '../img/img9.jpg'
import img3 from '../img/img3.jpg'
import img4 from '../img/img4.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const CategoryList = () => {
    const { t, i18n } = useTranslation(['about']);
    return (
        <div>
            <Category  image={img1}>
                <div className="category__texts">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="category__heading"><strong>{t('skill1')}</strong></h2>
                                <div dangerouslySetInnerHTML={{__html: t('category1')}} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Category>
            <Category title="Testowanie" image={img9}>
                <div className="category__texts">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="category__heading"><strong>{t('skill2')}</strong></h2>
                                <div dangerouslySetInnerHTML={{__html: t('category2')}} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Category>
            <Category title="Aplikacje React" image={img3}>
                <div className="category__texts">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="category__heading"><strong>{t('skill3')}</strong></h2>
                                <div dangerouslySetInnerHTML={{__html: t('category3')}} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Category>
            <Category title="Aplikacje mobilne" image={img4}>
                <div className="category__texts">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="category__heading"><strong>{t('skill4')}</strong></h2>
                                <div dangerouslySetInnerHTML={{__html: t('category4')}} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Category>
        </div>
    )
}

export default CategoryList