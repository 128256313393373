import { CHANGE_LANG, CHANGE_OPENED } from '../constants/action-types'
import i18next from 'i18next';

const initState = {
    opened: false,
    lang: "pl"
}

function rootReducer(state = initState, action) {
    switch(action.type) {
        case CHANGE_LANG:
            console.log("lang " + action.payload)
            i18next.changeLanguage(action.payload);
            return Object.assign({}, state, { lang: action.payload })
        case CHANGE_OPENED: 
            return Object.assign({}, state, { opened: !state.opened } )
        default:
            return state

    }
}

export default rootReducer