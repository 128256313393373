import React, { Component, useState } from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import IconDeer from './IconDeer'
import { connect } from 'react-redux'
import './Header.scss'
import { changeLang, changeOpened } from '../actions'
import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
    return { 
        lang: state.lang,
        opened: state.opened 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeOpened: () => dispatch(changeOpened()),
        changeLang: (lang) => dispatch(changeLang(lang))
    }
}


const Header = (props) => {
    const { t, i18n } = useTranslation(['nav']);
    return  (
        <header className="header">
            <Container>
                <Navbar expand="md" variant="light">
                    <LinkContainer to="/" exact>
                        <Navbar.Brand href="/" className="header__brand">
                            <IconDeer className="header__icon"/><span className="header__name">OlenLab</span>
                        </Navbar.Brand>
                    </LinkContainer> 
                    <select className="select mobile" onChange={(e) => props.changeLang(e.target.value)}>
                        <option defaultValue={ props.lang }>pl</option>
                        <option value="en">en</option>
                        <option value="de">de</option>
                        <option value="fr">fr</option>
                        <option value="ru">ru</option>
                        <option value="ua">ua</option>
                    </select>       
                    <Navbar.Toggle className="header__button" aria-controls="basic-navbar-nav" onClick={() => props.changeOpened()}>
                        { !props.opened ? 
                        <FontAwesomeIcon icon={faBars} className="header--blacked"/> : 
                        <FontAwesomeIcon icon={faTimes} className="header--blacked"/> }
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto header__links">
                            <LinkContainer to="/" exact>
                                <Nav.Link href="/" className="header__link header--blacked header--underlined header--hovered">{t('about')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/skills">
                                <Nav.Link active={false} className="header__link header--blacked header--hovered">{t('skills')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/portfolio">
                                <Nav.Link active={false} href="/portfolio" className="header__link header--blacked header--hovered">{t('portfolio')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/contact">
                                <Nav.Link active={false} href="/contact" className="header__link header--blacked header--hovered">{t('contact')}</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    <select className="select desktop" onChange={(e) => props.changeLang(e.target.value)}>
                        <option defaultValue={ props.lang }>pl</option>
                        <option value="en">en</option>
                        <option value="de">de</option>
                        <option value="fr">fr</option>
                        <option value="ru">ru</option>
                        <option value="ua">ua</option>
                    </select>
                </Navbar>
            </Container>
        </header>
    )
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)