import React from 'react';
import './Contact.scss'
import { Container }  from 'react-bootstrap'
import MapContainer from './MapContainer'
import photo from '../img/1.jpg'
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t, i18n } = useTranslation(['contact']);
    return (
        <div className="contact">
            <Container>
                <div>
                    <h2 className="contact__heading"><strong>{t('contact')}</strong></h2>
                    <div className="contact__details">
                        <div className="contact__detail">
                            <img className="contact__photo" src={photo}/>
                        </div>
                        <div className="contact__detail">
                            <h4 className="contact__name">Marcin Mazur</h4>
                            <p className="contact__tel">Tel.: <strong><a href="tel:+48797234595">+48797234595</a></strong></p>
                            <p className="contact__mail">Email: <strong><a href="mailto:marci.mazur@gmail.com">marcin.mazur@olenlab.com</a></strong></p>
                            <p></p>
                            <div dangerouslySetInnerHTML={{__html: t('desc')}} />
                        </div>
                    </div>
                </div>
            </Container>
            <MapContainer />
        </div>
    )
}

export default Contact