import * as React from "react";

function IconNode(props) {
  return (
    <svg viewBox="0 0 2249 2249" width="1em" height="1em" {...props}>
      <path
        d="M2249 1124.5c0 621-503.5 1124.5-1124.5 1124.5-573 0-1045.9-428.5-1115.6-982.6C3 1219.3 0 1171.9 0 1124.5c0-88 10.1-173.7 29.2-255.9C145.1 370.9 591.5 0 1124.5 0c531.7 0 977.2 369 1094.3 864.9 15.6 65.8 25.1 132.9 28.6 200.5 1.1 19.7 1.6 39.4 1.6 59.1z"
        fill="#f2f2f2"
      />
      <path
        d="M1068.3 1214.8V922.4L815.1 776.3 561.9 922.4v292.4L815.1 1361z"
        fill="#3b8739"
      />
      <path
        d="M2065.4 1170.4l165 95.3-165 95.3-253.2-146.2V922.5l253.2-146.2 153.4 88.6c15.6 65.8 25.1 132.9 28.6 200.5l-93.8 54.2v-101.8l-88.2-50.9-88.2 50.9v101.8z"
        fill="#333"
      />
      <path
        d="M2114.6 1097v-56.8l-49.1-28.4-49.2 28.4v56.8l49.2 28.4z"
        fill="#66a060"
      />
      <path
        d="M442.3 922.5V1308l-168.9-96.3V1014l-84.3-48.7-84.3 48.7v197.6l-95.9 54.7c-5.9-47-8.9-94.4-8.9-141.8 0-88 10.1-173.7 29.2-255.9l159.9-92.3z"
        fill="#333"
      />
      <path d="M815 776.2l253.2 438.5L815 1360.9 561.9 922.4z" fill="#6da75d" />
      <path d="M561.9 1214.8l86.9-141.8-86.9-150.6z" fill="#55a344" />
      <path d="M983.8 1068.6l84.4-146.2-253.1-146.1z" fill="#639d57" />
      <path
        d="M1522.3 437.4v387.9l-84.9-49-253.2 146.2v292.4l253.2 146.2 253.2-146.2V527.5zm-.5 679.9l-84.4 48.7-84.4-48.7v-97.4l84.4-48.7 84.4 48.7z"
        fill="#333"
      />
      <g fill="#66a060">
        <path d="M1239.4 1575.7s-5-42.7-50.7-42.7-77 6-77 31.3 88.9 25.3 122.7 35.3c33.8 9.9 63.6 35.8 48.7 81.5s-60.6 50.7-131.2 50.7-86.5-58.6-86.5-81.5h39.8s-5 35.8 41.7 41.7 91.4 9.3 97.4-26.8c8-47.7-151.1-23.9-165-70.6-11.8-39.5-5-91.4 74.5-99.4s123.2 27.2 123.2 79.5z" />
        <path d="M855.3 1762l87.9 50.7c44.2 25.5 99.4-6.4 99.4-57.4v-252.6h-35.8v242.6c0 27.8-30.1 45.3-54.3 31.3l-61.3-35.4v-258.9l224.2-129.4 224.2 129.4v258.9l-224.2 129.4-64.6-37.3-42.2 17 106.8 61.6 260-150.1v-300.2l-260-150.1-260 150.1z" />
      </g>
    </svg>
  );
}

const MemoIconNode = React.memo(IconNode);
export default MemoIconNode;
