
import * as React from "react";

function IconVisualStudioCode(props) {
  return (
    <svg viewBox="0 0 234 235.1" width="1em" height="1em" {...props}>
      <path
        d="M83.3 143.9l-58 45.2L0 176.5V58.7L25.2 46l57.6 45.3L174 0l60 23.9v186.9l-59.7 24.3-91-91.2zm88.9 15.9V75.3l-54.6 42.3 54.6 42.2zM27.3 144.6L56 118.5 27.3 89.9v54.7z"
        fill="#0179cb"
      />
    </svg>
  );
}

const MemoIconVisualStudioCode = React.memo(IconVisualStudioCode);
export default MemoIconVisualStudioCode;
