import * as React from "react";

function IconTest(props) {
  return (
    <svg height="1em" viewBox="0 0 512 512" width="1em" {...props}>
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={0}
        x2={512}
        y1={252.822}
        y2={252.822}
      >
        <stop offset={0} stopColor="#ffdbed" stopOpacity={0} />
        <stop offset={1} stopColor="#ffdbed" />
      </linearGradient>
      <path
        d="M20.337 130.334C-3.63 169.732-6.156 237.947 11.4 280.59c15.377 37.351 45.966 72.41 86.12 76.801 14.766 1.615 29.858-1.024 44.472 1.638 50.257 9.152 68.952 71.09 108.01 104.014 27.182 22.913 64.898 31.021 100.174 26.607s68.302-20.363 97.376-40.824c22.957-16.157 44.436-36.022 55.764-61.707 23.744-53.839-4.19-118.501-47.587-158.239-13.516-12.377-28.581-23.294-39.875-37.727-11.293-14.434-18.48-33.695-13.099-51.214 4.293-13.977 15.849-24.812 20.227-38.763 5.958-18.984-3.035-40.281-17.802-53.616s-34.308-20.039-53.666-24.64C270.411 3.639 92.691 11.395 20.337 130.334z"
        fill="url(#prefix__a)"
      />
      <path
        d="M350.232 497.161H161.768c-16.569 0-30-13.431-30-30V86.483c0-16.569 13.431-30 30-30h188.464c16.569 0 30 13.431 30 30v380.678c0 16.568-13.431 30-30 30z"
        fill="#2626bc"
        opacity={0.1}
      />
      <path
        d="M350.232 473.161H161.768c-16.569 0-30-13.431-30-30V62.483c0-16.569 13.431-30 30-30h188.464c16.569 0 30 13.431 30 30v380.678c0 16.568-13.431 30-30 30z"
        fill="#6583fe"
      />
      <path d="M131.77 75.392h248.46v354.86H131.77z" fill="#fff" />
      <path
        d="M350.232 478.161H161.768c-19.299 0-35-15.701-35-35V62.483c0-19.299 15.701-35 35-35h188.465c19.299 0 35 15.701 35 35v380.678c-.001 19.299-15.702 35-35.001 35zM161.768 37.483c-13.785 0-25 11.215-25 25v380.678c0 13.785 11.215 25 25 25h188.465c13.785 0 25-11.215 25-25V62.483c0-13.785-11.215-25-25-25z"
        fill="#2626bc"
      />
      <path
        d="M273.483 59.853h-34.966a5 5 0 010-10h34.966a5 5 0 010 10z"
        fill="#b7c5ff"
      />
      <circle cx={296.813} cy={54.851} fill="#b7c5ff" r={5.002} />
      <path
        d="M351.45 109.252v84.9c0 5.52-4.48 10-10 10h-170.9c-5.52 0-10-4.48-10-10v-84.9c0-5.52 4.48-10 10-10h170.9c5.52 0 10 4.48 10 10z"
        fill="#9fb0fe"
      />
      <path
        d="M351.45 128.292v65.86c0 5.52-4.48 10-10 10H219.57a81.88 81.88 0 01-.56-9.58c0-45.73 37.07-82.8 82.81-82.8 18.61 0 35.8 6.15 49.63 16.52z"
        fill="#8399fe"
      />
      <path
        d="M351.45 390.562v39.69h-190.9v-39.69c0-5.52 4.48-10 10-10h170.9c5.52 0 10 4.48 10 10z"
        fill="#02ffb3"
      />
      <path
        d="M239.06 405.412c0 8.94-2.14 17.38-5.93 24.84h-72.58v-39.69c0-5.52 4.48-10 10-10h62.58a54.758 54.758 0 015.93 24.85z"
        fill="#97ffd2"
      />
      <g>
        <path
          d="M244.294 277.534h-78.742a5 5 0 01-5-5v-39.739a5 5 0 015-5h78.742a5 5 0 015 5v39.739a5 5 0 01-5 5z"
          fill="#ff7eb8"
        />
        <path
          d="M346.449 277.534h-78.742a5 5 0 01-5-5v-39.739a5 5 0 015-5h78.742a5 5 0 015 5v39.739a5 5 0 01-5 5z"
          fill="#02ffb3"
        />
        <g>
          <path
            d="M244.294 338.92h-78.742a5 5 0 01-5-5v-39.739a5 5 0 015-5h78.742a5 5 0 015 5v39.739a5 5 0 01-5 5z"
            fill="#8399fe"
          />
          <path
            d="M346.449 338.92h-78.742a5 5 0 01-5-5v-39.739a5 5 0 015-5h78.742a5 5 0 015 5v39.739a5 5 0 01-5 5z"
            fill="#ffc4df"
          />
        </g>
      </g>
      <g>
        <path
          d="M251.668 157.981h-65.681a5 5 0 000 10h65.681a5 5 0 000-10zM251.668 138.553h-65.681a5 5 0 000 10h65.681a5 5 0 000-10z"
          fill="#fff"
        />
        <path
          d="M197.668 115.208h-11.681a5 5 0 000 10h11.681a5 5 0 000-10z"
          fill="#ffc4df"
        />
      </g>
      <g>
        <path
          d="M207.232 360.128h-41.681a5 5 0 000 10h41.681a5 5 0 000-10z"
          fill="#8399fe"
        />
      </g>
      <path
        d="M351.45 390.562v24.59c-18.95 0-34.32-15.36-34.32-34.31v-.28h24.32c5.52 0 10 4.48 10 10z"
        fill="#01eca5"
      />
      <path
        d="M421.705 167.419c-7.88 0-14.291-6.411-14.291-14.292s6.411-14.292 14.291-14.292c7.881 0 14.292 6.411 14.292 14.292s-6.411 14.292-14.292 14.292zm0-18.583c-2.366 0-4.291 1.925-4.291 4.292s1.925 4.292 4.291 4.292 4.292-1.925 4.292-4.292-1.926-4.292-4.292-4.292z"
        fill="#ff5ba8"
      />
      <path
        d="M430.997 210.191c-7.881 0-14.292-6.411-14.292-14.292s6.411-14.292 14.292-14.292c7.88 0 14.291 6.411 14.291 14.292s-6.411 14.292-14.291 14.292zm0-18.583c-2.366 0-4.292 1.925-4.292 4.292s1.926 4.292 4.292 4.292 4.291-1.925 4.291-4.292-1.925-4.292-4.291-4.292z"
        fill="#6583fe"
      />
      <path
        d="M83.007 123.288a5 5 0 01-5-5c0-3.309-2.691-6-6-6a5 5 0 110-10c3.309 0 6-2.691 6-6a5 5 0 1110 0c0 3.309 2.691 6 6 6a5 5 0 110 10c-3.309 0-6 2.691-6 6a5 5 0 01-5 5zM87.757 417.521a5 5 0 01-5-5c0-3.309-2.691-6-6-6a5 5 0 110-10c3.309 0 6-2.691 6-6a5 5 0 1110 0c0 3.309 2.691 6 6 6a5 5 0 110 10c-3.309 0-6 2.691-6 6a5 5 0 01-5 5z"
        fill="#01eca5"
      />
      <path
        d="M72.007 369.565a5 5 0 01-5-5c0-3.309-2.691-6-6-6a5 5 0 110-10c3.309 0 6-2.692 6-6a5 5 0 1110 0c0 3.309 2.691 6 6 6a5 5 0 110 10c-3.309 0-6 2.691-6 6a5 5 0 01-5 5z"
        fill="#ff5ba8"
      />
    </svg>
  );
}

const MemoIconTest = React.memo(IconTest);
export default MemoIconTest;
