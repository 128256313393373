import * as React from "react";

function IconPhotoshop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 130.746 595.279 580.398"
      {...props}
    >
      <radialGradient
        id="prefix__a"
        cx={-183.69}
        cy={328.972}
        r={0.76}
        gradientTransform="matrix(545.6736 0 0 528.3113 100439.305 -173525.125)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#34364e" stopOpacity={0.98} />
        <stop offset={1} stopColor="#0c0824" />
      </radialGradient>
      <path
        d="M24.803 155.549h545.674v530.792H24.803V155.549z"
        fill="url(#prefix__a)"
      />
      <path
        d="M24.803 155.549h545.674v530.792H24.803V155.549zM0 711.145h595.28V130.746H0v580.399zm401.318-342.287c-19.595 0-26.291 9.921-26.291 18.106 0 8.929 4.464 15.13 30.756 28.772 38.941 18.851 51.095 36.957 51.095 63.497 0 39.685-30.26 61.016-71.186 61.016-21.579 0-40.182-4.465-50.847-10.665-1.736-.744-1.984-1.984-1.984-3.969v-36.461c0-2.48 1.24-3.225 2.977-1.984 15.626 10.17 33.484 14.634 49.854 14.634 19.595 0 27.78-8.185 27.78-19.347 0-8.929-5.705-16.866-30.757-29.764-35.221-16.866-49.854-33.98-49.854-62.504 0-31.997 25.052-58.536 68.457-58.536 21.331 0 36.213 3.225 44.398 6.945 1.984 1.24 2.48 3.224 2.48 4.96v33.98c0 1.984-1.24 3.225-3.721 2.48-10.913-6.943-27.035-11.16-43.157-11.16zm-213.309 29.516c5.705.496 10.17.496 20.091.496 29.021 0 56.304-10.169 56.304-49.606 0-31.5-19.595-47.375-52.583-47.375-9.921 0-19.347.496-23.812.744v95.741zM143.86 266.668c0-1.736 3.473-2.977 5.456-2.977 15.875-.744 39.438-1.24 63.993-1.24 68.705 0 95.492 37.701 95.492 85.82 0 63-45.638 90.036-101.693 90.036-9.425 0-12.649-.496-19.347-.496v95.245c0 1.984-.744 2.976-2.976 2.976h-37.949c-1.984 0-2.977-.744-2.977-2.976V266.668z"
        fill="#31c5f0"
      />
    </svg>
  );
}

const MemoIconPhotoshop = React.memo(IconPhotoshop);
export default MemoIconPhotoshop;
