import React from 'react'
import "./Legal.scss"
import { Container, Row, Col } from 'react-bootstrap'


const Legal = () => {
    return (
        <div className="Legal">
            <Container>
                <Row>
                    <Col>
                    
                        <h1>Informacje ogólne</h1>
                        <p>Niniejsza Polityka Prywatności określa sposób pozyskiwania, przetwarzania oraz zabezpieczania danych osobowych w rozumieniu ustawy o ochronie danych osobowych z dnia 29 sierpnia 1997 roku (Dz.U. Nr 133, poz. 883 z póź. zm.) oraz ustawą o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. Nr 144, poz. 1204 z póź. zm.)</p>
                        <p>Właścicielem strony internetowej olenlab.com oraz administratorem danych osobowych jest Marcin Mazur.</p>
                        <h1>Dane osobowe</h1>
                        <p>Serwis zbiera informacje podane dobrowolnie przez użytkownika.</p>
                        <p>Dane osobowe są pozyskiwane podczas wypełniania formularza newsletter</p>
                        <p>Dane osobowe są wykorzystywane wyłącznie w celu wysyłania newslettera oraz o ile wyraził na to zgodę.</p>
                        <p>Zawartość strony internetowej można przeglądać bez podawania jakichkolwiek danych osobowych.</p>
                        <p>Każda osoba, która udostępniła swoje dane osobowe ma prawo do dostępu do ich treści oraz możliwość ich poprawiania, uaktualniania, uzupełniania, jak i również żądania zaprzestania przetwarzania danych osobowych oraz wniesienia sprzeciwu wobec przetwarzania danych osobowych. Wymienione czynności można dokonać poprzez wysłanie stosownego oświadczenia na adres email: marcin.mazur@olenlab.com</p>
                        <p>Pozyskane przez administratora dane osobowe są przechowywane, przetwarzane i chronione zgodnie z obowiązującymi przepisami prawa. Zbiór danych osobowych został zgłoszony do Urzędu Ochrony Danych Osobowych (uodo.gov.pl)</p>

                        <p>Administrator chroni zgromadzone dane osobowe korzystając z następujących środków:</p>
                        <ul>
                            <li>szyfrowanie danych służących do zapisu do newslettera</li>
                            <li>zabezpieczenie zbioru danych przed nieuprawnionym dostępem</li>
                        </ul>
                        <h1>Informacja o plikach cookies.</h1>
                        <p>Serwis korzysta z plików cookies.</p>
                        <p>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
                        <p>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</p>
                        <p>Pliki cookies wykorzystywane są w następujących celach:</p>
                        <ul>
                            <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
                            <li>utrzymania sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
                            <li>określania profilu użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google.</li>
                        </ul>
                        <p>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p>
                        <p>Programowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p>
                        <p>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</p>
                        <p>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.</p>
                        <p>Zalecamy przeczytanie polityki ochrony prywatności tych firm, aby poznać zasady korzystania z plików cookies wykorzystywane w statystykach: Polityka ochrony prywatności Google Analytics.</p>
                        <p>Pliki cookies mogą być wykorzystane przez sieci reklamowe, w szczególności sieć Google, do wyświetlenia reklam dopasowanych do sposobu, w jaki użytkownik korzysta z Serwisu. W tym celu mogą zachować informację o ścieżce nawigacji użytkownika lub czasie pozostawania na danej stronie.</p>
                        <p>W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/</p>

                        <h1>Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</h1>
                        <p>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</p>
                        <p>W celu zarządzania ustawieniami cookies wybierz z listy poniżej przeglądarkę internetową/ system i postępuj zgodnie z instrukcjami</p>
                        <ul>
                            <li>Internet Explorer</li>
                            <li>Chrome</li>
                            <li>Safari</li>
                            <li>Firefox</li>
                            <li>Opera</li>
                            <li>Android</li>
                            <li>Safari (iOS)</li>
                            <li>Windows Phone</li>
                            <li>Blackberry</li>
                        </ul>
                        <h1>Udostępnienie danych.</h1>
                        <p>Dane podlegają udostępnieniu podmiotom zewnętrznym wyłącznie w granicach prawnie dozwolonych.</p>
                        <p>Operator może mieć obowiązek udzielania informacji zebranych przez Serwis upoważnionym organom na podstawie zgodnych z prawem żądań w zakresie wynikającym z żądania.</p>
                        <h1>Postanowienia końcowe</h1>
                        <p>Administrator ma prawo do zmian w niniejszej Polityce Prywatności. Osoby udostępniające swoje dane osobowe obowiązuje aktualnie obowiązująca wersja Polityki Prywatności, dostępna na stronie olenlab.com</p>
                        <p>W sprawach nieuregulowanych niniejszą Polityką Prywatności stosuje się obowiązujące przepisy prawa polskiego.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Legal