import * as React from "react";

function IconDeer(props) {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
      <circle cx={196} cy={316} r={15} />
      <circle cx={316} cy={316} r={15} />
      <path d="M346 150h90c24.813 0 45-20.187 45-45V75c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 8.271-6.729 15-15 15h-15V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v45h-15c-8.271 0-15-6.729-15-15V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 24.813 20.187 45 45 45h15v30h-45c-24.813 0-45 20.187-45 45v21.882c-13.856-3.886-28.94-5.882-45-5.882s-31.144 1.996-45 5.882V165c0-24.813-20.187-45-45-45h-45V90h15c24.813 0 45-20.187 45-45V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 8.271-6.729 15-15 15h-15V15c0-8.284-6.716-15-15-15S91 6.716 91 15v105H76c-8.271 0-15-6.729-15-15V75c0-8.284-6.716-15-15-15s-15 6.716-15 15v30c0 24.813 20.187 45 45 45h90c8.271 0 15 6.729 15 15v34.362A116.375 116.375 0 00164.327 211H46c-8.284 0-15 6.716-15 15 0 41.355 33.645 75 75 75h15.588a189.97 189.97 0 00-.588 15c0 90.639 58.971 196 135 196s135-105.361 135-196c0-5.113-.201-10.112-.588-15H406c41.355 0 75-33.645 75-75 0-8.284-6.716-15-15-15H347.673A116.487 116.487 0 00331 199.362V165c0-8.271 6.729-15 15-15zM126.76 271H106c-19.556 0-36.239-12.639-42.43-30.1h75.632c-5.275 9.135-9.441 19.263-12.442 30.1zm112.815 113.32c-6.652-9.977.481-23.32 12.481-23.32h7.889c11.992 0 19.137 13.337 12.481 23.32L256 408.958l-16.425-24.638zM448.43 241c-6.191 17.461-22.874 30-42.43 30h-20.76c-3.001-10.837-7.167-20.865-12.442-30h75.632zm-112.454 0C350.614 256.68 361 280.361 361 316c0 68.017-40.146 149.737-90 163.86v-39.319l26.387-39.58c19.914-29.87-1.551-69.961-37.443-69.961h-7.889c-35.899 0-57.353 40.097-37.442 69.961L241 440.542v39.318c-49.854-14.124-90-95.844-90-163.86 0-35.639 10.386-59.32 25.024-75H181v.348c0 8.284 6.716 15 15 15s15-6.716 15-15v-22.899c16.839-6.121 33.633-7.449 45-7.449s28.161 1.328 45 7.449v22.899c0 8.284 6.716 15 15 15s15-6.716 15-15V241h4.976z" />
    </svg>
  );
}

const MemoIconDeer = React.memo(IconDeer);
export default MemoIconDeer;
