import * as React from "react";

function IconCode(props) {
  return (
    <svg height="1em" viewBox="1 -10 512 511" width="1em" {...props}>
      <path
        d="M366.758 455.313H145.242l15.285-31.348 16.313-33.438h158.32l16.313 33.438zm0 0"
        fill="#e6e6e6"
      />
      <path
        d="M430.496 473.46c0 10.024-8.129 18.142-18.148 18.142H99.64c-5.016 0-9.551-2.028-12.832-5.309a18.108 18.108 0 01-5.305-12.832c0-10.031 8.117-18.149 18.137-18.149h312.707c5.004 0 9.55 2.028 12.832 5.31a18.08 18.08 0 015.316 12.839zm0 0"
        fill="#ccc"
      />
      <path
        d="M430.496 473.46c0 10.024-8.129 18.142-18.148 18.142H202.043c-5.016 0-9.55-2.028-12.832-5.309a18.111 18.111 0 01-5.309-12.832c0-10.031 8.118-18.149 18.141-18.149h210.305c5.004 0 9.55 2.028 12.832 5.31a18.08 18.08 0 015.316 12.839zm0 0"
        fill="#e6e6e6"
      />
      <path
        d="M512 51.145v288.738c0 27.972-22.676 50.644-50.645 50.644H50.637C22.676 390.527 0 367.855 0 339.883V51.145C0 23.175 22.676.5 50.637.5h410.718C489.325.5 512 23.176 512 51.145zm0 0"
        fill="#42c0e2"
      />
      <path
        d="M512 298.578v41.305c0 27.972-22.676 50.644-50.645 50.644H50.637C22.676 390.527 0 367.855 0 339.883v-41.305zm0 0"
        fill="#e6e6e6"
      />
      <path
        d="M351.473 423.965H160.527l16.313-33.438h158.32zm0 0M276.895 343.512c0 11.539-9.356 20.898-20.899 20.898-11.539 0-20.898-9.36-20.898-20.898 0-11.543 9.359-20.899 20.898-20.899 11.543 0 20.899 9.356 20.899 20.899zm0 0"
        fill="#ccc"
      />
      <path d="M197.95 30.055H334.3v136.351H197.95zm0 0" fill="#218f68" />
      <path d="M308.738 64.629l-93.601 162.129h187.207zm0 0" fill="#ffd061" />
      <path
        d="M274.746 202.453c0 39.817-32.281 72.094-72.098 72.094-39.816 0-72.093-32.277-72.093-72.094 0-39.816 32.277-72.094 72.093-72.094 39.817 0 72.098 32.278 72.098 72.094zm0 0"
        fill="#fa70b2"
      />
    </svg>
  );
}

const MemoIconCode = React.memo(IconCode);
export default MemoIconCode;
