import React, { Suspense } from 'react';
import './App.scss';
import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'
import Skills from './components/Skills'
import Legal from './components/Legal'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers/index'
import { LoopCircleLoading } from 'react-loadingg';
import "./i18n";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

function App() {
  return (
    <Suspense fallback={<LoopCircleLoading color="#000000" />}>

      <Provider store={store}>
        <div className="App">
            <Router className="App__router">
              <Header />
              <Switch>
                <Route path="/skills">
                  <Skills/>
                </Route>
                <Route path="/portfolio">
                  <Portfolio/>
                </Route>
                <Route path="/contact">
                  <Contact/>
                </Route>
                <Route path="/legal">
                  <Legal />
                </Route>
                <Route path="/">
                  <Main />
                </Route>
              </Switch>
              <Footer />
            </Router>
        </div>
      </Provider>
    </Suspense>
  );
}



export default App;
