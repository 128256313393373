import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './Footer.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t, i18n } = useTranslation(['nav']);
    return (
    <footer className="footer">
        <Container>
            <Row xs={1} sm={3} className="info">
                <Col>
                    <ul className="links footer__menu">  
                        <li><Link to="/" className="link white" href="">{t('about')}</Link></li>
                        <li><Link to="/skills" className="link white" href="">{t('skills')}</Link></li>
                        <li><Link to="/portfolio" className="link white" href="">{t('portfolio')}</Link></li>
                        <li><Link to="/contact" className="link white" href="">{t('contact')}</Link></li>
                    </ul>
                </Col>
                <Col>
                    <div className="footer__contact">
                        <p className="white">{t('contact_me')}</p>
                        <a className="contact__link grey" href="mailto:marci.mazur@gmail.com">marcin.mazur@olenlab.com</a>
                        <a className="contact__link grey" href="tel:+48797234595">+48 797 234 595</a>
                    </div>
                </Col>
                <Col>
                    <div className="footer__materials">
                        <p>{t('royalty')}</p>
                        <div>Icons made by <a href="https://www.flaticon.com/authors/darius-dan" title="Darius Dan">Darius Dan</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                        <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    </div>
                </Col>
            </Row>
            <div >
            </div>
        </Container>
        <Container>
            <Row xs={1} sm={3} className="medias">
                <Col>
                    <p className="footer__media text">© Marcin Mazur {t('copyright')}</p>
                </Col>
                <Col>
                    <Link to="/legal" className="footer__media medias__link">{t('legal')}</Link>
                </Col>
                <Col>
                    <div className="footer__media footer__socials socials">
                        <p className="text">{t('social_media')}</p>
                        <a className="link" href="https://www.facebook.com/marcin.mazur.olenlab" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faFacebookSquare} size="2x" /></a>
                        <a className="link" href="https://www.instagram.com/olenlab/" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faInstagramSquare} size="2x" /></a>
                        <a className="link" href="https://www.linkedin.com/in/marcin-mazur-39957270/" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
    )
}

export default Footer